import './SocialMediaList.css'
import SocialMedia from '../SocialMedia'
import MyEmail from '../MyEmail'

const SocialMediaList = () => {

    return (
        <ul>
            <SocialMedia name='Github' />
            <SocialMedia name='LinkedIn' />
            <SocialMedia name='Twitter' />
            <SocialMedia name='Instagram' />
            <MyEmail name='Email'/>
        </ul>
    )

}

export default SocialMediaList