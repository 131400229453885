import './Logo.css'

export const Logo = () => {
    return (

        <section id='container'>
            <h1>Michel Radtke</h1>
            <img src='/img/caric.png' alt='Imagem da caricatura do Michel' id='fotoPerfil'/>
            <h2 id='container__subtitle'>Meu Portfolio</h2>
        </section>


    )
}

export default Logo