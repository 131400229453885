import './MyEmail.css'

const MyEmail = (props) => {

    const sMediaPath = `/img/${props.name}.png`
    const myEmail = 'michel@michelradtke.com.br'
    const sendingMeEmail = 'mailto:michel@michelradtke.com.br'

    return (
        <div id='emailLink'>
            <img className='imagemEmail' src={sMediaPath} alt='Imagem do logotipo da rede social'/>
            <p>{myEmail}</p>
        </div>
    )
}

export default MyEmail