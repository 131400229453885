import PortfolioCard from './components/PortfolioCard';
// import Logo from './components/Logo';
// import SocialMedia from './components/SocialMedia'
// import SocialMediaList from './components/SocialMediaList';

function App() {
  return (
    <div className="App">
      <PortfolioCard id='portfolioCard'/>
    </div>
  );
}

export default App;
