import './SocialMedia.css'

const SocialMedia = (props) => {

    const sMediaImagePath = `img/${props.name}.png`
    let socialMediaPath = ''
    switch (props.name) {
        case 'Github':
            socialMediaPath = 'https://www.github.com/michelradtke'
            break;
        case 'LinkedIn':
            socialMediaPath = 'https://www.linkedin.com/in/michelradtke'
            break;
        case 'Twitter':
            socialMediaPath = 'http://www.twitter.com/michelradtke'
            break;
        case 'Instagram':
            socialMediaPath = 'http://www.instagram.com/michelradtke'
            break;
        default:
            alert('Isso não é possível no momento.')
            break;
    }

    return (
        <li>
            <img className='imagemLogo' src={sMediaImagePath} alt='Imagem do logotipo da rede social'/>
            <a href={socialMediaPath}>{props.name}</a>
        </li>
    )
}

export default SocialMedia
