import './PortfolioCard.css'
import Logo from '../Logo'
import SocialMediaList from '../SocialMediaList'

const PortfolioCard = () => {
    return (
        <main>
            <Logo />
            <SocialMediaList />
        </main>
    )
}

export default PortfolioCard